import {
  ConnectionBuilderFactory,
} from '../manifest/manifest-definitions';
import {BaseComponentModel, BaseEditorComponent, ConnectedComponentModel} from './base-editor-component';

export enum SlotRepeaterState {
  LOADING = 'loaderState',
  LIST = 'slotRepeaterState',
  NO_CLASSES = 'noClassesState'
}

export enum DailyTimetableComponentRoles {
  DATE_RANGE_LABEL = 'dateRangeLabel',
  TIMETABLE_TITLE = 'timetableTitle',
  LEFT_BUTTON = 'leftButton',
  RIGHT_BUTTON = 'rightButton',
  SLOT_REPEATER = 'slotRepeater',
  SLOT_CONTAINER = 'slotContainer',
  DAYS_TOOLBAR = 'daysToolbar',
  DAYS_TOOLBAR_DIVIDER = 'daysToolbarDivider',
  DAY_ITEM = 'dayItem',
  DAY = 'day',
  DAY_PICKER_BUTTON = 'dayPickerButton',
  STATE_BOX = 'statebox',
  LOADER_IMAGE = 'loaderImage',
  TIME = 'time',
  DURATION = 'duration',
  SERVICE_NAME = 'serviceName',
  STAFF_MEMBER = 'staffMember',
  SPOTS = 'spots',
  SERVICES_DIVIDER = 'servicesDivider',
  MESSAGE_TEXT = 'messageText',
  MESSAGE_BOX = 'messageBox',
  NO_SERVICES_DIVIDER = 'noServicesDivider',
  BOOK_BUTTON = 'bookButton',
  BOOK_BUTTON_CONTAINER = 'bookButtonContainer',

}

export class DailyTimetableEditorComponentModel extends BaseEditorComponent {
  constructor(editorSdkAdapter, editorTranslation, manageBookingsAction, manageStates, openTimetableSettings, openTimetableTextSettings, isBookableTimeTable) {
    const connectionFactory = new ConnectionBuilderFactory(editorTranslation);
    const connectedComponents = [
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.TIMETABLE_TITLE,
        connectionFactory.editableText(
          editorTranslation.t('bookings.daily-timetable.component.label.TimetableHeading'),
          null,
          ),
        'bookings.daily-timetable.component.content.TimetableHeading',
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.MESSAGE_TEXT,
        connectionFactory.editableText(
          editorTranslation.t('bookings.daily-timetable.component.label.EmptyState'),
          '2af923bc-c554-4877-b9c7-569f66041543'),
        'bookings.daily-timetable.component.content.EmptyState,'
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.DATE_RANGE_LABEL,
        connectionFactory.uneditableText(
          editorTranslation.t('bookings.daily-timetable.component.label.DateDisplay'),
          '754cf8c4-b35c-4260-b8ef-0788e23c4455',
          ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.LEFT_BUTTON,
        connectionFactory.link(
          editorTranslation.t('bookings.daily-timetable.component.label.ArrowButton'),
          '5024d220-2018-4d5f-8cab-40d79e883082',
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.RIGHT_BUTTON,
        connectionFactory.link(
          editorTranslation.t('bookings.daily-timetable.component.label.ArrowButton'),
          '5024d220-2018-4d5f-8cab-40d79e883082',
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.SLOT_REPEATER,
        connectionFactory.repeater(
          editorTranslation.t('bookings.daily-timetable.component.label.ClassesAvailable'),
          'e94d3f14-f28f-49a8-ba21-69fc1932118f',
          {mainAction1: isBookableTimeTable ? openTimetableSettings : manageBookingsAction, withoutLayout: true, shouldHideActionsOnMobile: isBookableTimeTable},
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.SLOT_CONTAINER,
        connectionFactory.repeaterItem(
          editorTranslation.t('bookings.daily-timetable.component.label.ClassDetails'),
          '2af923bc-c554-4877-b9c7-569f66041543',
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.DAYS_TOOLBAR,
        connectionFactory.repeater(
          editorTranslation.t('bookings.daily-timetable.component.label.WeekDisplay'),
          'e94d3f14-f28f-49a8-ba21-69fc1932118f',
          {mainAction1: manageBookingsAction, withoutLayout: true},
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.DAYS_TOOLBAR_DIVIDER,
        connectionFactory.divider(),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.DAY_ITEM,
        connectionFactory.repeaterItem(
          editorTranslation.t('bookings.daily-timetable.component.label.DayContainer'),
          '2af923bc-c554-4877-b9c7-569f66041543',
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.DAY,
        connectionFactory.uneditableText(
          editorTranslation.t('bookings.daily-timetable.component.label.DayText'),
          '754cf8c4-b35c-4260-b8ef-0788e23c4455',
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.DAY_PICKER_BUTTON,
        connectionFactory.linkDisabledText(
          editorTranslation.t('bookings.daily-timetable.component.label.DateButton'),
          '754cf8c4-b35c-4260-b8ef-0788e23c4455',
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.STATE_BOX,
        connectionFactory.stateBox(
          editorTranslation.t('bookings.daily-timetable.component.label.StateBox'),
          '1a2e6176-790c-4384-9180-3cffbfd6d459',
          {mainAction2: isBookableTimeTable ? openTimetableSettings : manageBookingsAction, shouldHideActionsOnMobile: isBookableTimeTable},
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.LOADER_IMAGE,
        connectionFactory.loaderImage(
          editorTranslation.t('bookings.daily-timetable.component.label.ClassesLoading'),
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.TIME,
        connectionFactory.uneditableText(
          editorTranslation.t('bookings.daily-timetable.component.label.Time'),
          '754cf8c4-b35c-4260-b8ef-0788e23c4455',
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.DURATION,
        connectionFactory.uneditableText(
          editorTranslation.t('bookings.daily-timetable.component.label.Duration'),
          '754cf8c4-b35c-4260-b8ef-0788e23c4455',
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.SERVICE_NAME,
        connectionFactory.uneditableText(
          editorTranslation.t('bookings.daily-timetable.component.label.ServiceName'),
          '754cf8c4-b35c-4260-b8ef-0788e23c4455',
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.STAFF_MEMBER,
        connectionFactory.uneditableText(
          editorTranslation.t('bookings.daily-timetable.component.label.StaffText'),
          '754cf8c4-b35c-4260-b8ef-0788e23c4455',
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.SPOTS,
        connectionFactory.uneditableText(
          editorTranslation.t('bookings.daily-timetable.component.label.Availability'),
          '754cf8c4-b35c-4260-b8ef-0788e23c4455',
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.SERVICES_DIVIDER,
        connectionFactory.divider(
          editorTranslation.t('bookings.daily-timetable.component.label.Separator'),
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.MESSAGE_BOX,
        connectionFactory.textBox(
          editorTranslation.t('bookings.daily-timetable.component.label.EmptyStateContainer'),
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.NO_SERVICES_DIVIDER,
        connectionFactory.divider(
          editorTranslation.t('bookings.daily-timetable.component.label.Separator'),
        ),
      ),
      new ConnectedComponentModel(
        SlotRepeaterState.LIST,
        connectionFactory.stateBoxState(
          editorTranslation.t('bookings.daily-timetable.component.label.ClassesAvailable'),
        ),
      ),
      new ConnectedComponentModel(
        SlotRepeaterState.LOADING,
        connectionFactory.stateBoxState(
          editorTranslation.t('bookings.daily-timetable.component.label.ClassesLoading'),
        ),
      ),
      new ConnectedComponentModel(
        SlotRepeaterState.NO_CLASSES,
        connectionFactory.stateBoxState(
          editorTranslation.t('bookings.daily-timetable.component.label.NoClasses'),
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.BOOK_BUTTON,
        connectionFactory.link(
          editorTranslation.t('bookings.daily-timetable.component.label.BookButton'),
          '',
          {mainAction1: openTimetableTextSettings, shouldHideActionsOnMobile: true},
        ),
      ),
      new ConnectedComponentModel(
        DailyTimetableComponentRoles.BOOK_BUTTON_CONTAINER,
        connectionFactory.unselectableBox(),
      ),
    ];
    const arrowButtonLinkedStyles = [DailyTimetableComponentRoles.LEFT_BUTTON, DailyTimetableComponentRoles.RIGHT_BUTTON];
    const componentModel = new BaseComponentModel({
      nickname: 'timetableWidget',
      label: editorTranslation.t('bookings.daily-timetable.component.label.Timetable'),
      mainAction1: manageBookingsAction,
      mainAction2: isBookableTimeTable ? openTimetableSettings : manageStates,
      helpId: '0b516ee7-36ca-48c8-9a1d-95ce926802ef',
      connectedComponents,
      linkedStyles: [arrowButtonLinkedStyles],
      additionalConfiguration: {
        shouldHideMainAction2OnMobile: isBookableTimeTable,
      },
    });
    super(editorSdkAdapter, componentModel);
  }
}
